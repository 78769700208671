import React from 'react';
import { render } from "react-dom";

import App from './App';

import './styles/themify-icons.css';
import './styles/style.css';
import './fonts/icomoon/style.css';

const rootElement = document.getElementById("root");

render(<App />, rootElement);
