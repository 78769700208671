import {Link} from 'react-router-dom';
import BgMain from './img/bg-main.webp';
import IcoDownload from './img/ico-download.svg';

const Software = () =>
    <div className="main">
        <section className="hero-section ptb-100 background-img"
                 style={{background: `url(${BgMain})no-repeat center center / cover`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                            <h1 className="text-white mb-0">Необходимые сведения о ПО</h1>
                            <div className="custom-breadcrumb">
                                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                    <li className="list-inline-item breadcrumb-item"><Link to='/'>Главная</Link></li>
                                    <li className="list-inline-item breadcrumb-item active">Необходимые сведения о ПО
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="pt-100">
            <div className="container">
                <div className="row pb-4">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading">
                            <h3>О продукте</h3>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>Findy - рекламная платформа, позволяющая управлять привлечением кандидатов в онлайн и офлайн
                            каналах. Наша команда ежедневно усердно работает чтобы понизить стоимость и увеличить скорость,
                            сохраняя качество рекрутмента. Благодаря нашей автоматизированной платформе все этапы возможно
                            отслеживать онлайн.</p>
                        <p>Программа работает из браузера Google Chrome, Firefox, Opera и Safari</p>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading">
                            <h4>Классификация ПО «Findy»</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>Раздел:<br />Информационные системы для решения специфических отраслевых задач</p>
                        <p>Основной класс: 05.15<br />Информационные системы для решения специфических отраслевых задач</p>
                        <p>58.29.29<br />Обеспечение программное прикладное прочее на электронном носителе.</p>
                        <p>62<br />Продукты программные и услуги по разработке программного обеспечения; консультационные и аналогичные услуги в области информационных технологий</p>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading">
                            <h4>Сведения о правообладателе ПО «Findy»</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>Общество с ограниченной ответственностью «Экспириум»</p>
                        <p>
                            Юридический адрес: 109544, город Москва, б-р Энтузиастов, д. 2, этаж 13 ком. 36<br />
                            ИНН: 9705161966<br />
                            КПП: 770501001<br />
                            ОГРН: 1217700567710
                        </p>
                        <p>
                            Сайт: <a href="https://findy.ru/">www.findy.ru</a><br />
                            Электронная почта: <a href="mailto:help@finfy.ru">help@finfy.ru</a>
                        </p>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading">
                            <h4>Сведения о государственной регистрации ПО «Findy»</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p>Номер: 2021660825<br />
                            Дата регистрации: 01.07.2021</p>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading">
                            <h4>Документация</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="single-feature">
                            <div className="icon-box-wrap d-flex align-items-center mb-0">
                                <div className="mr-3 icon-box">
                                    <img width="auto" height="auto" className="img-fluid" src={IcoDownload}
                                         alt="download"/>
                                </div>
                                <p className="mb-0"><a href={"/downloads/FINDY_domeny_lendingi_nastrojka.docx"} download>Общее
                                    руководство по установке и эксплуатации ПО «Findy»</a></p>
                            </div>
                        </div>
                        <div className="single-feature">
                            <div className="icon-box-wrap d-flex align-items-center mb-0">
                                <div className="mr-3 icon-box">
                                    <img width="auto" height="auto" className="img-fluid" src={IcoDownload}
                                         alt="download"/>
                                </div>
                                <p className="mb-0"><a href={"/downloads/FINDY_zhiznennyj_cikl_podderzhki_sistemy.docx"} download>Описание
                                    процессов жизненного цикла</a></p>
                            </div>
                        </div>
                        <div className="single-feature">
                            <div className="icon-box-wrap d-flex align-items-center mb-0">
                                <div className="mr-3 icon-box">
                                    <img width="auto" height="auto" className="img-fluid" src={IcoDownload}
                                         alt="download"/>
                                </div>
                                <p className="mb-0"><a href={"/downloads/Findy_install.docx"} download>Инструкция по установке экземпляра
                                    программного обеспечения</a></p>
                            </div>
                        </div>
                        <div className="single-feature">
                            <div className="icon-box-wrap d-flex align-items-center">
                                <div className="mr-3 icon-box">
                                    <img width="auto" height="auto" className="img-fluid" src={IcoDownload}
                                         alt="download"/>
                                </div>
                                <p className="mb-0"><a href={"https://drive.google.com/file/d/1Jbg7TTC8NoUHcUfUIoBfEHajZdtCLp6v/view?usp=sharing"}>Скачать экземпляр ПО</a></p>
                            </div>
                        </div>

                        <p>ПО «Findy» — решение SaaS (Software as a Service). Доступ к полной версии ПО «Findy»
                            предоставляется путем создания личного кабинета на сайте правообладателя.</p>
                        <p>Вы можете ознакомиться с основными функциями ПО «Findy», войдя в личный кабинет ПО «Findy» и
                            используя <Link to='/instruction'>Общее руководство по установке и эксплуатации «Findy»</Link>.
                        </p>
                        <p>Полный перечень функциональности и информация о тарифах доступна на <a
                            href="https://findy.ru/">www.findy.ru</a></p>
                    </div>
                </div>
            </div>
        </section>
    </div>
;

export default Software;
