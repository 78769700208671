import { Link } from 'react-router-dom';
import BgMain from './img/bg-main.webp';
import IcoDownload from './img/ico-download.svg';

const Instruction = () =>
    <div className="main">
        <section className="hero-section ptb-100 background-img" style={{background: `url(${BgMain})no-repeat center center / cover`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                            <h1 className="text-white mb-0">Инструкция</h1>
                            <div className="custom-breadcrumb">
                                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                    <li className="list-inline-item breadcrumb-item"><Link to='/'>Главная</Link></li>
                                    <li className="list-inline-item breadcrumb-item active">Инструкции</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="promo-section pt-100">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading mb-5">
                            <span className="text-uppercase color-secondary sub-title">Руководство пользователя</span>
                            <h3 className="mb-6">Настройка и установка Findy</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="number">
                            <li>открыть регистрационную ссылку в электронном письме, полученном от компании Экспириум</li>
                            <li>перейти по ссылке в один из поддерживаемых продутом Findy браузеров (Chrome 90+ (two last versions), Microsoft Edge 90+(two last versions), Opera 75+ (two last versions), Firefox 78+ (esr+), Safari 14+ (last major version), Яндекс.Браузер 21+ (last major version)) на страницу регистрации, расположенную по адресу Findy.ru</li>
                            <li>в открывшемся окне пройти процедуру установки пароля (ввести пароль, далее ввести пароль повторно)</li>
                            <li>далее в открывшемся окне авторизации ввести в поле "Логин" адрес электронной почты и в поле "Пароль" - пароль, который был установлен ранее в окне установки пароля.</li>
                            <li>авторизовавшийся пользователь попадет в интерфейс своего личного кабинета в системе <a href="https://findy.ru/">Findy.ru</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section className="promo-section ptb-60">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading mb-4">
                            <h4>Скачайте инструкции</h4>
                        </div>
                        <div className="single-feature mb-3">
                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                <div className="mr-3 icon-box">
                                    <img width="auto" height="auto" className="img-fluid" src={IcoDownload} alt="download" />
                                </div>
                                <p className="mb-0"><a href={"/downloads/Findy_domain.docx"} download>Инструкция по интеграции Findy с корпоративным сайтом компании (домен)</a></p>
                            </div>
                        </div>
                        <div className="single-feature mb-3">
                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                <div className="mr-3 icon-box">
                                    <img width="auto" height="auto" className="img-fluid" src={IcoDownload} alt="download" />
                                </div>
                                <p className="mb-0"><a href={"/downloads/Findy_landing.docx"} download>Инструкция по созданию/ использованию лендинговых страниц в сервисе Findy</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

export default Instruction;
