import { Fragment } from "react";
import { Link } from "react-router-dom";

import BgMain from './img/bg-main.webp';
import IcoDownload from './img/ico-download.svg';

const PrivacyPolicy = () =>
    <Fragment>
        <div className="main">
            <section className="hero-section ptb-100 background-img" style={{background: `url(${BgMain})no-repeat center center / cover`}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-10">
                            <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                <h1 className="text-white mb-0">Политика в отношении обработки персональных данных</h1>
                                <div className="custom-breadcrumb">
                                    <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                        <li className="list-inline-item breadcrumb-item"><Link to='/'>Главная</Link></li>
                                        <li className="list-inline-item breadcrumb-item active">Политика в отношении обработки персональных данных</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="promo-section mt-5 ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <h5>1. Мы заботимся о защите персональгных данных</h5>
                            <p>Команда <a href="https://experium.ru/company/"><strong>Experium</strong></a> (ООО «Экспириум») со всей серьезностью относится к исполнению требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» и к обеспечению безопасного хранения персональной информации субъектов. В нашей компании за организацию обработки персональных данных отвечает отдельный сотрудник. Он следит за тем, чтобы процессы по работе с информацией соответствовали требованиям законодательства.</p>
                            <p>Также мы разработали политики и локальные акты, которые регламентируют процедуры по работе с информацией, обеспечивают безопасность данных, предотвращают и выявляют нарушения законодательства. С этими документами мы в обязательном порядке ознакомили под роспись всех наших работников.</p>
                            <p>Предоставляя нашим заказчикам доступ к сервису FINDY, мы исполняем (согласно ч.3 ст.6 152-ФЗ) функции обработчика персональных данных по поручению наших клиентов. При этом мы реализуем все правовые, организационные и технические меры для обеспечения безопасности информации (в соответствии со ст. 19 152-ФЗ</p>
                            { // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            }<p>Более подробно, как мы обрабатываем информацию в сервисе FINDY, описано в <a href="#" data-toggle="modal" data-target="#myModalOPD"><strong>«Частной политике в отношении обработки персональных данных»</strong></a>.</p>
                        </div>
                        <div className="col-md-12 mb-4">
                            <h5>2. Контакты ответственного за обработку персональных данных</h5>
                            <p>Контактное лицо: <strong>Парфенова Ольга Николаевна</strong><br />
                            Почта: <a href="mailto:privacy@experium.ru"><strong>privacy@experium.ru</strong></a></p>
                        </div>
                        {/*<div className="col-md-12 mb-4">
                            <h5>3. Внесение в Реестр Операторов персональных данных</h5>
                            <p>Компания ООО «Экспириум» зарегистрирована в Роскомнадзоре в качестве оператора персональных данных в соответствии с Федеральным законом РФ № 152-ФЗ «О персональных данных».
            Регистрационный номер 77-21-019054.</p>
                        </div>*/}
                        <div className="col-md-12 mb-4">
                            <h5>3. Политика обработки Персональных данных</h5>
                            {
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            }<p>Актуальная версия Политики в свободном доступе расположена <a href="" data-toggle="modal" data-target="#myModalOPD">здесь</a></p>
                        </div>
                        {/*<div className="col-md-12 mb-4">*/}
                        {/*    <h5>5. Использование защищенного и аттестованного хостинга</h5>*/}
                        {/*    <p>Информация, что мы используем защищенный и аттестованный хостинг.</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        </div>

        <div className="modal inmodal fade" id="myModalOPD" tabindex="-1" role="dialog"  aria-hidden="true">
            <div className="modal-dialog modal-full">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true"><i className="ti ti-close"></i></span></button>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <h4>Частная политика в отношении обработки персональных данных в платформе</h4>
                                        <p className="mb-4"><small>Версия 1.0 от 15.07.2021 </small></p>
                                        <p>Защита персональных данных физических лиц имеет большое значение для ООО «Экспириум» (далее – Experium, мы). Документ «Частная политика в отношении обработки персональных данных в Платформе» (далее – Политика) подробно описывает, какого рода информацию мы собираем и обрабатываем в процессе функционирования сервиса Findy.</p>
                                        <p>Experium обрабатывает персональные данные физических лиц на законных основаниях в соответствии с ч. 1 ст. 6 № 152-ФЗ «О персональных данных», для осуществления функционирования сервиса Findy, а также, выполняет функции обработчика персональных данных, порученных нашими клиентами.</p>
                                        <p>Как оператор и обработчик персональных данных Experium реализовал многочисленные технические и организационные меры для обеспечения максимально полной защиты персональных данных.</p>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Кто мы и как с нами связаться</h5>
                                        <h6>Общество с ограниченной ответственностью «Экспириум»</h6>
                                        <p>
                                            Юридический адрес: 109544, город Москва, б-р Энтузиастов, д. 2, этаж 13 ком. 36<br />
                                            Фактический адрес: 109544, г. Москва, бульвар Энтузиастов, дом 2, этаж 13, комната 36<br />
                                            E-mail: <a href="mailto:info@experium.ru">info@experium.ru</a><br />
                                            Веб-сайт: <a href="https://experium.ru/">www.experium.ru</a>
                                        </p>

                                        <h6>Ответственный за обработку и защиту данных:</h6>
                                        <p>
                                            Парфенова Ольга<br />
                                            Адрес: 109544, город Москва, Бульвар Энтузиастов, дом 2, БЦ Golden Gate, башня A<br />
                                            E-mail: <a href="mailto:privacy@experium.ru">privacy@experium.ru</a>
                                        </p>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Принципы обработки персональных данных</h5>
                                        <p>При обработке ПДн Experium придерживается следующих принципов:</p>
                                        <ul className="bullet">
                                            <li>осуществляет обработку персональных данных только на законной и справедливой основе;</li>
                                            <li>определяет конкретные законные цели до начала обработки (в том числе сбора) персональных данных;</li>
                                            <li>собирает только те персональные данные, которые являются необходимыми и достаточными для заявленной цели обработки;</li>
                                            <li>ограничивает обработку персональных данных при достижении конкретных, заранее определенных и законных целей;</li>
                                            <li>производит уничтожение персональных данных по достижении целей обработки или в случае утраты необходимости в достижении целей (если иное не предусмотрено договором или иным соглашением с субъектом данных либо законодательством Российской Федерации).</li>
                                        </ul>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Условия и способы обработки персональных данных в Experium</h5>
                                        <p>Обработка персональных данных в Experium осуществляется только при наличии надлежащего правового основания: с согласия субъекта на обработку его персональных данных, если иное не предусмотрено действующим законодательством Российской Федерации в области персональных данных.</p>
                                        <p>В Experium не осуществляется обработка специальных категорий персональных данных субъектов персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни.</p>
                                        <p>Обработка биометрических персональных данных может осуществляться Experium только на основании письменного согласия субъекта персональных данных, если иное не предусмотрено действующим законодательством Российской Федерации.</p>
                                        <p>Experium не использует персональные данные субъектов персональных данных для принятия решений на основании исключительно их автоматизированной обработки, порождающих юридические последствия в отношении субъекта персональных данных или иным образом затрагивающих их права и законные интересы.</p>
                                        <p>Experium обрабатывает персональные данные о состоянии здоровья только в объеме, необходимом в целях соблюдения трудового законодательства Российской Федерации.</p>
                                        <p>В случае трансграничной передачи персональных данных в страны, не обеспечивающие защиту прав субъектов персональных данных, за исключением случаев, предусмотренных законодательством Российской Федерации, трансграничная передача осуществляется на основании отдельного согласия в письменной форме.</p>
                                        <p>Experium не размещает персональные данные в общедоступных источниках без отдельного предварительного согласия в письменной форме субъекта персональных данных.</p>
                                        <p>Experium не раскрывает, не передает третьим лицам и не распространяет персональные данные без наличия надлежащего правового основания. При передаче персональных данных субъектов персональных данных третьи лица Experium обязательно предупреждаются о конфиденциальности передаваемых сведений.</p>
                                        <p>Experium может выполнять обработку персональных данных по поручению клиентов с согласия субъекта персональных данных, если иное не предусмотрено законодательством Российской Федерации, на основании заключаемого с этими лицами договора (далее – поручение оператора).</p>
                                        <p>Experium, осуществляющий обработку персональных данных на основании заключаемого с клиентами договора (поручения оператора), обязуется соблюдать принципы и правила обработки и защиты персональных данных, предусмотренные законодательством Российской Федерации. В таком договоре определяются перечень действий (операций) с персональными данными, которые будут совершаться Experium, осуществляющим обработку персональных данных, цели обработки персональных данных, устанавливается обязанность соблюдать конфиденциальность и обеспечивать безопасность персональных данных при их обработке, указываются требования к защите обрабатываемых персональных данных в соответствии с законодательством Российской Федерации.</p>
                                        <p>В случае если клиент поручает обработку персональных данных Experium, ответственность перед субъектом персональных данных несет клиент. Experium, осуществляющий обработку персональных данных по поручению клиента как оператора персональных данных, несет ответственность перед клиентом.</p>
                                        <p>Experium осуществляет обработку персональных данных следующими способами:</p>
                                        <ul className="bullet">
                                            <li>с использованием средств автоматизации, в том числе с использованием информационных технологий и технических средств, включая средства вычислительной техники, информационно-технические комплексы и сети, средства и системы передачи, приема и обработки персональных данных, программные средства (операционные системы, системы управления базами данных и т.п.), средства защиты информации, применяемые в информационных системах;</li>
                                            <li>без использования средств автоматизации.</li>
                                        </ul>
                                        <p>Совокупность операций обработки персональных данных, осуществляемых в системе Findy, включает сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение персональных данных.</p>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Персональные данные, которые Experium обрабатывает, на каких основаниях, с какой целью и как долго</h5>
                                        <table className="table table-bordered table-small">
                                            <colgroup>
                                                <col width="16%" />
                                                <col width="16%" />
                                                <col width="26%" />
                                                <col width="26%" />
                                                <col width="16%" />
                                            </colgroup>
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th>Категория субъектов персональных данных</th>
                                                    <th>Цели обработки</th>
                                                    <th>Обрабатываемые данные</th>
                                                    <th>Правовое основание</th>
                                                    <th>Срок хранения</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>Пользователи (Работники клиентов Experium)</th>
                                                    <td>Предоставление сервиса Findy</td>
                                                    <td>
                                                        <ul className="bullet">
                                                            <li>Фамилия, имя, отчество;</li>
                                                            <li>Номер мобильного телефона;</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <ul className="bullet">
                                                            <li>Адрес электронной почты</li>
                                                            <li>Лицензионный договор</li>
                                                            <li>Согласие на передачу персональных данных</li>
                                                        </ul>
                                                    </td>
                                                    <td>До прекращения действия лицензионного договора</td>
                                                </tr>
                                                <tr>
                                                    <th>Кандидаты на вакантные должности клиентов</th>
                                                    <td>Обработка данных субъектов по поручению клиентов в рамках предоставления сервиса Findy</td>
                                                    <td>
                                                        <ul className="bullet">
                                                            <li>фамилия, имя и отчество;</li>
                                                            <li>пол;</li>
                                                            <li>фотография;</li>
                                                            <li>контактный телефон;</li>
                                                            <li>адрес электронной почты;</li>
                                                            <li>дата рождения;</li>
                                                            <li>гражданство;</li>
                                                            <li>город и район проживания;</li>
                                                            <li>адрес личной интернет-страницы в социальных сетях;</li>
                                                            <li>готовность к переезду и командировкам;</li>
                                                            <li>семейное положение;</li>
                                                            <li>опыт работы;</li>
                                                            <li>сведения об образовании;</li>
                                                            <li>сведения о предыдущих местах работы;</li>
                                                            <li>уровень владения компьютером;</li>
                                                            <li>уровень владения иностранными языками;</li>
                                                            <li>зарплатные ожидания;</li>
                                                            <li>рекомендации с предыдущих мест работы</li>
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <ul className="bullet">
                                                            <li>Лицензионный договор</li>
                                                            <li>Согласие на поручение персональных данных</li>
                                                        </ul>
                                                    </td>
                                                    <td>До прекращения действия лицензионного договора</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Источник персональных данных</h5>
                                        <p>Персональные данные субъектов персональных данных могут быть получены Experium в рамках поручения клиентами на обработку персональных данных в рамках оказания сервиса Findy. Также, клиенты могут передавать данные собственных работников Experium для создания пользовательских учетных записей без поручения на обработку.</p>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Получатели персональных данных, которым Experium может передавать данные или поручать данные на обработку</h5>
                                        <p>Хостинг-провайдер</p>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Защита персональных данных</h5>
                                        <p>В Experium реализована совокупность мер, направленных на обеспечение безопасности персональных данных в процессе управленческой и производственной деятельности Experium.</p>
                                        <p>Для защиты персональных данных в Experium установлены следующие меры:</p>
                                        <ul className="bullet">
                                            <li>назначение лица, ответственного за организацию обработки персональных данных, и лица, ответственного за обеспечение безопасности персональных данных в информационных системах;</li>
                                            <li>разработка и утверждение локальных актов по вопросам обработки и защиты персональных данных;</li>
                                            <li>применение средств защиты информации;</li>
                                            <li>учет материальных носителей персональных данных;</li>
                                            <li>обнаружение фактов несанкционированного доступа к персональным данным и принятие мер по недопущению подобных инцидентов в дальнейшем;</li>
                                            <li>восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
                                            <li>установление правил доступа к персональным данным, обрабатываемым в информационных системах, а также обеспечение регистрации и учета всех действий, совершаемых с персональными данными в информационных системах;</li>
                                            <li>контроль за принимаемыми мерами по обеспечению безопасности персональных данных и уровнем защищенности информационных систем;</li>
                                            <li>оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения требований законодательства, соотношение указанного вреда и принимаемых Experium мер, направленных на обеспечение выполнения обязанностей, предусмотренных законодательством;</li>
                                            <li>соблюдение условий, исключающих несанкционированный доступ к материальным носителям персональных данных и обеспечивающих сохранность персональных данных;</li>
                                            <li>ознакомление работников Experium, непосредственно осуществляющих обработку персональных данных, с положениями законодательства о персональных данных, в том числе с требованиями к защите персональных данных, локальными актами по вопросам обработки и защиты персональных данных;</li>
                                            <li>организация порядка уничтожения информации, содержащей персональные данные;</li>
                                            <li>разъяснительная работа с работниками Experium по предупреждению угроз или опасности утраты персональных данных;</li>
                                            <li>организация пропускного режима в Experium;</li>
                                            <li>организация порядка охраны территории, зданий, помещений Experium.</li>
                                        </ul>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Права и обязанности Experium как обработчика персональных данных</h5>
                                        <p>Experium имеет право:</p>
                                        <ul className="bullet">
                                            <li>Осуществлять обработку персональных данных, переданных ему клиентом на основании поручения на обработку персональных данных с согласия субъектов персональных данных;</li>
                                            <li>Перенаправлять все направляемые ему запросы субъектов персональных данных и государственных органов, связанные с обработкой персональных данных, порученных клиентом на обработку самому клиенту;</li>
                                            <li>Запрашивать и получать от клиентов документы, подтверждающие законность обработки поручаемых ему персональных данных.</li>
                                        </ul>
                                        <p>Experium обязан:</p>
                                        <ul className="bullet">
                                            <li>соблюдать принципы и правила обработки персональных данных, предусмотренные Федеральным законом № 152-ФЗ «О персональных данных», а также принимать меры, необходимые и достаточные для обеспечения выполнения обязанностей, возложенных на него договором, в соответствии с требованиями Федерального закона № 152-ФЗ «О персональных данных», принятыми в соответствии с ним нормативными правовыми актами, и принятыми Experium локальными нормативными правовыми актами;</li>
                                            <li>обеспечить конфиденциальность персональных данных, ставших ему известными при исполнении обязанностей, а также безопасность персональных данных при их обработке в соответствии с требованиями Федерального закона № 152-ФЗ «О персональных данных» и других определяющих случаи и особенности обработки персональных данных федеральных законов;</li>
                                            <li>принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных, согласно ст. 19 Федерального закона № 152-ФЗ «О персональных данных».</li>
                                        </ul>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Права и обязанности Experium как оператора персональных данных</h5>
                                        <p>Experium имеет право:</p>
                                        <ul className="bullet">
                                            <li>предоставлять персональные данные государственным и иным уполномоченным органам, если это предусмотрено законодательством Российской Федерации (налоговые, правоохранительные органы и др.);</li>
                                            <li>отказывать в предоставлении персональных данных в случаях, предусмотренных законодательством Российской Федерации;</li>
                                            <li>обрабатывать персональные данные субъекта без его согласия в случаях, предусмотренных законодательством Российской Федерации.</li>
                                        </ul>
                                        <p>Experium обязан:</p>
                                        <ul className="bullet">
                                            <li>предоставлять субъектам персональных данных по их просьбе информацию, касающуюся обработки их персональных данных;</li>
                                            <li>разъяснить субъектам персональных данных юридические последствия их отказа в предоставлении Experium своих персональных данных;</li>
                                            <li>в случае получения персональных данных не от субъекта персональных данных, за исключением случаев, предусмотренных частью 4 статьи 18 Федерального закона от 27.07.2006 № 152, до начала обработки таких персональных данных предоставить субъекту персональных данных следующую информацию:
                                                <ul className="list-unstyled mt-3">
                                                    <li>1. наименование либо фамилия, имя, отчество и адрес оператора или его представителя;</li>
                                                    <li>2. цель обработки персональных данных и ее правовое основание;</li>
                                                    <li>3. предполагаемые пользователи персональных данных;</li>
                                                    <li>4. установленные Федеральным законом от 27.07.2006 № 152 права субъекта персональных данных;</li>
                                                    <li>5. источник получения персональных данных;</li>
                                                </ul>
                                            </li>
                                            <li>при сборе персональных данных, в том числе посредством сети Интернет, обеспечить запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации, за исключением случаев, указанных в пунктах 2, 3, 4, 8 части 1 статьи 6 Федерального закона от 27.07.2006 № 152.</li>
                                        </ul>
                                    </div>

                                    <div className="col-md-12 mb-4">
                                        <h5 className="text-center">Права субъектов персональных данных</h5>
                                        <ul className="number">
                                            <li>
                                                <p>Если Experium обрабатывает Ваши данные на основании Вашего согласия на обработку персональных данных, Вы можете отозвать его в любое время.</p>
                                                <p>В случае отзыва Вами согласия на обработку персональных данных, мы будем обязаны прекратить их обработку, в случае если других правовых оснований обработки персональных данных нет.</p>

                                                <div className="single-feature mb-3">
                                                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                        <div className="mr-3 icon-box">
                                                            <img width="auto" height="auto" className="img-fluid" src={IcoDownload} alt="download" />
                                                        </div>
                                                        <p className="mb-0"><a href={"/downloads/Forma-otziva-na-OPD.docx"} download>Скачать форму отзыва согласия на обработку персональных данных</a></p>
                                                    </div>
                                                </div>

                                                <p>В случае отзыва согласия просим Вас направить скан-копию оформленного отзыва по адресу <a href="mailto:privacy@experium.ru">privacy@experium.ru</a>. Отзыв на бумажном носителе Вы можете передать в офис Experium лично или отправить по почте заказным письмом с уведомлением о вручении. Как и предписывает законодательство о защите персональных данных, в срок, не превышающий тридцати дней с момента поступления отзыва в письменном виде, мы прекратим обработку Ваших персональных данных. Вам будет направлено подтверждение о прекращении обработки Ваших персональных данных.</p>
                                            </li>
                                            <li>
                                                <p>У Вас есть право на получение информации, касающейся обработки Ваших персональных данных.</p>
                                                <p>Вы как субъект персональных данных имеете право получать от Experium:</p>
                                                <ul className="bullet">
                                                    <li>подтверждение факта обработки персональных данных Experium;</li>
                                                    <li>сведения о правовых основаниях и целях обработки персональных данных;</li>
                                                    <li>сведения о применяемых Experium способах обработки персональных данных;</li>
                                                    <li>сведения о наименовании и местонахождении Experium, а также сведения о лицах (за исключением работников Experium), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с Experium или на основании законодательства Российской Федерации;</li>
                                                    <li>перечень обрабатываемых персональных данных, относящихся к Вам, и информацию об источнике их получения, если иной порядок представления таких данных не предусмотрен законодательством;</li>
                                                    <li>сведения о сроках обработки персональных данных, в том числе о сроках их хранения;</li>
                                                    <li>информацию об осуществленной или о предполагаемой трансграничной передаче персональных данных;</li>
                                                    <li>сведения о порядке осуществления субъектом персональных данных прав;</li>
                                                    <li>фамилию, имя, отчество либо наименование и адрес лица, осуществляющего обработку персональных данных по поручению Experium;</li>
                                                    <li>иные сведения, предусмотренные законодательными актами Российской Федерации.</li>
                                                </ul>

                                                <div className="single-feature mb-3">
                                                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                        <div className="mr-3 icon-box">
                                                            <img width="auto" height="auto" className="img-fluid" src={IcoDownload} alt="download" />
                                                        </div>
                                                        <p className="mb-0"><a href={"/downloads/Zapros-na-info.docx"} download>Скачать форму запроса на получение информации, касающейся обработки персональных данных</a></p>
                                                    </div>
                                                </div>

                                                <p>В случае запроса на получение информации просим Вас направить скан-копию оформленного запроса на адрес <a href="mailto:privacy@experium.ru">privacy@experium.ru</a>. Запрос на бумажном носителе Вы можете передать в офис Experium лично или отправить по почте заказным письмом с уведомлением о вручении. Как и предписывает законодательство о защите персональных данных, в срок, не превышающий тридцати дней с момента поступления запроса в письменном виде, мы предоставим запрошенную информацию, касающуюся обработки Ваших персональных данных.</p>
                                            </li>
                                            <li>
                                                <p>У Вас есть право на уточнение или удаление Ваших персональных данных.</p>
                                                <p>Вы можете потребовать исправить свои персональные данные, если они являются неточными, неполными или устаревшими. Если персональные данные не соответствуют действительности и данные, которые соответствуют действительности, доступны Experium, персональные данные будут исправлены.</p>
                                                <p>Также Вы можете потребовать блокирования или уничтожения Ваших персональных данных в случае, если они являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки.</p>
                                                <p>В случае подтверждения неверности или неточности персональных данных Experium известит всех лиц, которым ранее были сообщены неверные или неполные персональные данные, обо всех произведенных в них исключениях, исправлениях или дополнениях.</p>

                                                <div className="single-feature mb-3">
                                                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                        <div className="mr-3 icon-box">
                                                            <img width="auto" height="auto" className="img-fluid" src={IcoDownload} alt="download" />
                                                        </div>
                                                        <p className="mb-0"><a href={"/downloads/Zapros-subjekta.docx"} download>Скачать форму запроса субъекта персональных данных</a></p>
                                                    </div>
                                                </div>

                                                <p>Просим Вас направить скан-копию оформленного запроса на адрес <a href="mailto:privacy@experium.ru">privacy@experium.ru</a>. Запрос на бумажном носителе Вы можете передать в офис Experium лично или отправить по почте заказным письмом с уведомлением о вручении. Как и предписывает законодательство о защите персональных данных, в срок, не превышающий семи рабочих дней с момента окончания проверки достоверности предоставленных сведений, мы выполним запрос или предоставим мотивированный отказ в выполнении запроса.</p>
                                            </li>
                                            <li>
                                                <p>У Вас есть право обжаловать действия Experium.</p>
                                                <p>Вы как субъект персональных данных, имеете право обжаловать неправомерные действия или бездействие Experium при обработке персональных данных в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</p>
                                                <p>В соответствии со статьей 23 Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных» Уполномоченным органом по защите прав субъектов Российской Федерации является федеральный орган исполнительной власти, осуществляющий функции по контролю и надзору в сфере информационных технологий и связи.</p>
                                                <p>В настоящее время Уполномоченным органом по защите прав субъектов персональных данных является Федеральная служба по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор), а именно одно из ее управлений – Управление по защите прав субъектов персональных данных <a href="https://pd.rkn.gov.ru/">https://pd.rkn.gov.ru/</a></p>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
;

export default PrivacyPolicy;
