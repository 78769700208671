import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";

import Faq from "./Faq";
import Footer from "./Footer";
import Header from "./Header";
import Instruction from "./Instruction";
import SoftwareInformation from "./Software-information";
import Price from "./Price";
import Main from "./Main";
import PrivacyPolicy from "./PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";

function App() {
    return <Router>
        <ScrollToTop>
            <Header />
            <Switch>
                <Route exact component={Main} path='/' />
                <Route component={Faq} path='/faq' />
                <Route component={Instruction} path='/instruction' />
                <Route component={PrivacyPolicy} path='/policy' />
                <Route component={SoftwareInformation} path='/software-information' />
                <Route component={Price} path='/price' />
            </Switch>
            <Footer />
        </ScrollToTop>
    </Router>;
}

export default App;
