import { Link } from 'react-router-dom';
import LogoWhite from './img/logo-white-1x.webp';

const Header = () =>
    <header className="header">
        <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
            <div className="container">
                <Link className="navbar-brand" to='/'><img height="auto" src={LogoWhite} width="120" alt="logo" className="img-fluid" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="ti-menu" />
                </button>
                <div className="collapse navbar-collapse main-menu" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto mr-3 mb-4 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to='/price'>Цены</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/faq'>Часто задаваемые вопросы</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/instruction'>Инструкции</Link>
                        </li>
                    </ul>

                    <a href="https://app.findy.ru/" className="btn btn-sm outline-btn">Перейти в сервис</a>
                </div>
            </div>
        </nav>
    </header>;

export default Header;
