import { Link } from 'react-router-dom';
import BgMain from './img/bg-main.webp';

const Faq = () =>
    <div className="main">
        <section className="hero-section ptb-100 background-img" style={{background: `url(${BgMain})no-repeat center center / cover`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                            <h1 className="text-white mb-0">FAQ</h1>
                            <div className="custom-breadcrumb">
                                <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                    <li className="list-inline-item breadcrumb-item"><Link to='/'>Главная</Link></li>
                                    <li className="list-inline-item breadcrumb-item active">FAQ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="promo-section ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading mb-4">
                            <h3>Часто задаваемые вопросы</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">

                        <div id="accordion-1" className="accordion accordion-faq pb-60">
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-1" data-toggle="collapse" role="button" data-target="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Как добавить пользователя?</h6>
                                </div>
                                <div id="collapse-1-1" className="collapse" aria-labelledby="heading-1-1" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>В разделе «Компания» на вкладке «Пользователи» есть возможность добавить нового пользователя, указав данные о нем, присвоить роль и указать на какие источники разрешена публикация объявлений. При нажатии кнопки «Добавить» пользователю на указанную почту придет приглашение для входа в Findy. Если нажать на ФИО пользователя, можно повторно отправить инвайт.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-3" data-toggle="collapse" role="button" data-target="#collapse-1-3" aria-expanded="false" aria-controls="collapse-1-3">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Как добавить ссылку в QR код для баннера?</h6>
                                </div>
                                <div id="collapse-1-3" className="collapse" aria-labelledby="heading-1-3" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>Необходимо открыть баннер, кликнуть на QR код. В правой части шаблона в поле «Ссылка» вы можете указать любую ссылку, например, на анкету, на лендинг, на корпоративный сайт и т.д.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-4" data-toggle="collapse" role="button" data-target="#collapse-1-4" aria-expanded="false" aria-controls="collapse-1-4">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Что такое группировка и для чего она нужна?</h6>
                                </div>
                                <div id="collapse-1-4" className="collapse" aria-labelledby="heading-1-4" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>В блоке «Группировка» могут быть созданы разные группировки вакансий: по географическому принципу, функциональному направлению компании или любым другим признакам. Она поможет группировать вакансии, фильтровать их по созданным вами направлениям.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-5" data-toggle="collapse" role="button" data-target="#collapse-1-5" aria-expanded="false" aria-controls="collapse-1-5">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Что такое Классификатор и для чего он нужен?</h6>
                                </div>
                                <div id="collapse-1-5" className="collapse" aria-labelledby="heading-1-5" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>Классификаторы используются при публикации вакансии на сайте компании. Содержат списки классификаторов, которые служат критериями поиска вакансий на сайте компании. При выборе определенного классификатора список вакансий фильтруется согласно выбранному критерию.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-7" data-toggle="collapse" role="button" data-target="#collapse-1-7" aria-expanded="false" aria-controls="collapse-1-7">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Что такое барьерные вопросы, как это работает?</h6>
                                </div>
                                <div id="collapse-1-7" className="collapse" aria-labelledby="heading-1-7" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>Это стоп-вопросы в анкете, по которым формируется фильтр в Findy для откликов по кандидатам “Статусы по барьерным вопросам” (прошел/не прошел).  В анкету можно добавлять барьерные вопросы (для любого вопроса анкеты: предустановленного, кастомизированного и резервного) и установить какой ответ является барьерным. Например, вопрос - «уровень образования» и допустимый ответ «Высшее». При заполнении данного вопроса в анкете, ответив «Высшее» кандидат проходит барьерный вопрос, при ответе, например, «Среднее» не проходит. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-8" data-toggle="collapse" role="button" data-target="#collapse-1-8" aria-expanded="false" aria-controls="collapse-1-8">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Как изменить пароль для входа?</h6>
                                </div>
                                <div id="collapse-1-8" className="collapse" aria-labelledby="heading-1-8" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>В Findy кнопка «Профиль» в правом углу окна, активировать бегунок «Изменить пароль», внести новую информацию, далее «Сохранить».</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-9" data-toggle="collapse" role="button" data-target="#collapse-1-9" aria-expanded="false" aria-controls="collapse-1-9">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Какие есть боты в Findy и как их настроить?</h6>
                                </div>
                                <div id="collapse-1-9" className="collapse" aria-labelledby="heading-1-9" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>В боковом меню выберите «Боты» и перейдите во вкладки для настройки и подключения ботов: VIBER, Facebook Messenger, Telegram, Web-bot. Для подключения ботов в окне «Добавить бота» кликните на аватар бота и следуйте пошаговой инструкции. Для каждого бота свои шаги настроек. В блоке Боты представлены три вкладки: Основные, Контент, Боты. Во вкладке «Основные» вы можете настраивать общий текст приветствия и текст приветствия по вакансии, которые будут отображаться соискателям при переходе в бот. Во вкладке «Контент» вы можете настроить главное меню бота с дополнительным контентом.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-10" data-toggle="collapse" role="button" data-target="#collapse-1-10" aria-expanded="false" aria-controls="collapse-1-10">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Как добавить бота на вакансию?</h6>
                                </div>
                                <div id="collapse-1-10" className="collapse" aria-labelledby="heading-1-10" data-parent="#accordion-1" >
                                    <div className="card-body">
                                        <p>Нажмите кнопку «Настройки для чат-бота» в окне публикации вакансии (ШАГ.2 Информация) и в открывшемся окне «Настройки для чат-бота» укажите настройки вакансии в чат-ботах.</p>
                                        <p>Опубликовано – статус «Опубликовано» установлен автоматически. Для изменения статуса публикации вакансии в чат-боте переместите переключатель в левую сторону.</p>
                                        <p>Приветствие – текст приветствия в чат-ботах, установленный в настройках чат-ботов. При необходимости настройте индивидуальный текст приветствия для вакансии.</p>
                                        <p>Начало заполнения анкеты – текст, который отображается в чат-ботах при заполнении анкеты. При необходимости настройте индивидуальный текст для вакансии.</p>
                                        <p>Прощание – текст прощания в чат-ботах. При необходимости, настройте индивидуальный текст прощания с кандидатами по вакансии.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-10 col-lg-12 col-md-12">
                        <div className="section-heading mb-4">
                            <h3>Интеграция с ATS-системами</h3>
                            <h4 className="text-uppercase color-secondary sub-title">Experium</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div id="accordion-2" className="accordion accordion-faq pb-60">
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-2" data-toggle="collapse" role="button" data-target="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Где в Experium отображаются отклики на объявления, опубликованные в Findy?</h6>
                                </div>
                                <div id="collapse-1-2" className="collapse" aria-labelledby="heading-1-2" data-parent="#accordion-2" >
                                    <div className="card-body">
                                        <p>По умолчанию не отображаются. Для того, чтобы увидеть отклики, их необходимо скачать, как с работных сайтов. Далее эти отклики отображаются в портфеле откликов. Если отклики скачаны, они отображаются  в сводке проекта на вкладке «Отклики с сайтов» или «Отклики с лендингов и ботов».</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-6" data-toggle="collapse" role="button" data-target="#collapse-1-6" aria-expanded="false" aria-controls="collapse-1-6">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Как отображаются ответы кандидатов в Experium на кастомизированные вопросы в анкетах?</h6>
                                </div>
                                <div id="collapse-1-6" className="collapse" aria-labelledby="heading-1-6" data-parent="#accordion-2" >
                                    <div className="card-body">
                                        <p>Ответы сохраняются в документ-отклик, а также в сводке проекта, куда были скачаны отклики, формируются новые колонки с соответствующими названиями (кастомизированные вопросы).</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header py-4 collapsed" id="heading-1-11" data-toggle="collapse" role="button" data-target="#collapse-1-11" aria-expanded="false" aria-controls="collapse-1-11">
                                    <h6 className="mb-0"><span className="ti-help-alt mr-3"></span> Если создать вакансию в Findy, как полученные отклики увидеть в Experium и добавить в проект?</h6>
                                </div>
                                <div id="collapse-1-11" className="collapse" aria-labelledby="heading-1-11" data-parent="#accordion-2" >
                                    <div className="card-body">
                                        <p>Никак. Для того чтобы была возможность работать с откликами в программе Experium и добавлять их в проекты, необходимо вакансии публиковать в Findy из Experium. При публикации объявления из Experium в списке работных сайтов выберите Findy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    </div>;

export default Faq;
