import { Fragment } from 'react';
import footerBg from './img/footer-bg.webp';
import logoWhite from './img/logo-white-1x.webp';
import FooterTopShape from './img/footer-top-shape.webp';
import { Link } from 'react-router-dom';

const Footer = () =>
    <Fragment>
        <div className="shape-img subscribe-wrap">
			<img width="auto" height="auto" src={FooterTopShape} alt="footer shape" className="img-fluid" />
		</div>
        <footer className="footer-section">
            <div className="footer-top pt-150 background-img-2" style={{background: `url(${footerBg})no-repeat center top / cover`}}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-3 wow fadeIn" data-wow-delay="0.2s">
                            <div className="footer-nav-wrap text-white mb-3">
                                <img height="auto" src={logoWhite} alt="footer logo" width="120" className="img-fluid mb-3" />
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-1 wow fadeIn" data-wow-delay="0.3s">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="text-white">Адрес</h5>
                                    <div className="footer-nav-wrap text-white">
                                        <ul className="list-unstyled contact-list">
                                            <li className="ic ic-location">
                                                <span>109544, Москва,<br/>Бизнес-центр «Golden Gate», башня А,бульвар Энтузиастов, д. 2, 12 этаж</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.4s">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="text-white">Контакты</h5>
                                    <div className="footer-nav-wrap text-white">
                                        <ul className="list-unstyled contact-list">
                                            <li className="ic ic-mobile">
                                                <span><a href="tel:+74957836603"> +7 (495) 783-66-03</a> <small>(9:00-18:00 МСК)</small></span><br/>
                                                <span><a href="tel:+79150216424"> +7 (915) 021-64-24</a> <small>(отдел продаж)</small></span>
                                            </li>
                                            <li className="ic ic-email">
                                                <span><a href="mailto:help@findy.ru"> help@findy.ru</a></span><br/>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom border-gray-light mt-5 py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-lg-3">
                                <div className="copyright-wrap small-text">
                                    <p className="mb-0 text-white">© 2022 FINDY, Москва</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-lg-9">
                                <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><Link className="small-text" to='/software-information'>Необходимые сведения о ПО</Link></li>
                                        <li className="list-inline-item"><Link className="small-text" to='/policy'>Политика в отношении обработки персональных данных</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </Fragment>;

export default Footer;
