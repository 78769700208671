import BgMain from './img/bg-main.webp';
import HeroAnimation1 from './img/hero-animation-01.svg';
import HeroAnimation2 from './img/hero-animation-02.svg';
import HeroAnimation3 from './img/hero-animation-03.svg';
import HeroAnimation4 from './img/hero-animation-04.svg';
import HeroBgShape from './img/hero-bg-shape-1.svg';
import IcoWebSite from './img/services/ico-website.svg';
import IcoLanding from './img/services/ico-landing.svg';
import IcoChatbot from './img/services/ico-chatbot.svg';
import IcoSocial from './img/services/ico-social.svg';
import Image1 from './img/image-1.webp';
import Image2 from './img/image-2.webp';
import Image3 from './img/image-3.webp';
import Image4 from './img/image-4.webp';
import Image5 from './img/image-5.webp';
import LandingScreen1 from './img/landing/screen1.webp';
import LandingScreen2 from './img/landing/screen2.webp';
import LandingScreen3 from './img/landing/screen3.webp';
import LandingScreen4 from './img/landing/screen4.webp';
import LandingScreen5 from './img/landing/screen5.webp';
import LandingScreen6 from './img/landing/screen6.webp';
import BgLk from './img/bg-lk.webp';
import BgLanding from './img/bg-landing.webp';
import BgChatbot from './img/bg-chatbot.webp';

const Main = () =>
    <div className="main">
        <section className="hero-section pt-120 pb-80 background-img" style={{background: `url(${BgMain})no-repeat center center / cover`}}>
            <div className="circles">
                <div className="point animated-point-1"></div>
                <div className="point animated-point-2"></div>
                <div className="point animated-point-3"></div>
                <div className="point animated-point-4"></div>
                <div className="point animated-point-5"></div>
                <div className="point animated-point-6"></div>
                <div className="point animated-point-7"></div>
                <div className="point animated-point-8"></div>
                <div className="point animated-point-9"></div>
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-7 col-lg-6">
                        <div className="hero-content-left text-white wow fadeIn">
                            <p className="lead">Рекламная платформа, позволяющая управлять привлечением кандидатов в&nbsp;онлайн и офлайн каналах</p>
                            <a href="https://app.findy.ru/" className="btn outline-btn">Перейти в сервис</a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <div className="hero-animation-img wow fadeIn">
                            <img width="auto" height="auto" className="img-fluid d-none d-lg-block animation-one" src={HeroAnimation4} alt="hero-animation-4" />
                            <img height="auto" className="img-fluid d-none d-lg-block animation-two" src={HeroAnimation1} alt="hero-animation-1" width="120" />
                            <img height="auto" className="img-fluid d-none d-lg-block animation-three" src={HeroAnimation2} alt="hero-animation-2" width="120" />
                            <img height="auto" className="img-fluid d-none d-lg-block animation-four" src={HeroAnimation3} alt="hero-animation-3" width="230" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-img-absolute">
                <img width="auto" height="auto" src={HeroBgShape} alt="wave shape" className="img-fluid" />
            </div>
        </section>

        <section className="promo-section mt-5 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-10 col-md-12">
                        <div className="section-heading mb-5">
                            <span className="text-uppercase color-secondary sub-title">Как это работает</span>
                            <h3 className="mb-6">В несколько кликов на платформе можно активировать и использовать необходимые инструменты</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <a href="#site" className="service_single_content text-center mb-100 h-100 wow fadeIn page-scroll" data-wow-delay="0.2s">
                            <div>
                                <div className="service_icon">
                                    <img width="auto" height="auto" src={IcoWebSite} alt="" />
                                </div>
                                <h5>Корпоративные сайты</h5>
                                <p>Публикация на&nbsp;корпоративных (доменных) страницах компании</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <a href="#landing" className="service_single_content text-center mb-100 h-100 wow wow fadeIn page-scroll" data-wow-delay="0.3s">
                            <div>
                                <div className="service_icon">
                                    <img width="auto" height="auto" src={IcoLanding} alt="" />
                                </div>
                                <h5>Лендинговые страницы</h5>
                                <p>Конструктор лендинговых страниц</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <a href="#chatbot" className="service_single_content text-center mb-100 h-100 wow fadeIn page-scroll" data-wow-delay="0.4s">
                            <div>
                                <div className="service_icon">
                                    <img width="auto" height="auto" src={IcoChatbot} alt="" />
                                </div>
                                <h5>Чат-боты</h5>
                                <p>Сбор откликов через чат-боты</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0">
                        <a href="#social" className="service_single_content text-center mb-100 h-100 wow fadeIn page-scroll" data-wow-delay="0.5s">
                            <div>
                                <div className="service_icon">
                                    <img width="auto" height="auto" src={IcoSocial} alt="" />
                                </div>
                                <h5>Социальные сети</h5>
                                <p>Публикацию вакансий в&nbsp;соцсетях</p>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </section>

        <section className="download-section pt-100 pb-100 background-img wow fadeIn" data-wow-delay="0.2s" style={{background: `url(${BgLk})no-repeat center center / cover`}}>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <div className="download-content text-white">
                            <h2 className="text-white">Личный кабинет HR специалиста</h2>
                            <p className="lead py-3">Личный кабинет HR специалиста на платформе Findy представляет собой цифровой пульт управления всеми вышеперечисленными инструментами, с помощью которых можно в один клик начинать и прекращать рекламу вакансий и сбор откликов в нужных каналах.</p>
                            <div className="download-btn">
                                <a href="https://app.findy.ru/" className="btn outline-btn"><span className="ti-lock"></span> Перейти в сервис</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="download-img d-flex align-items-end">
                            <img width="auto" height="auto" src={Image1} alt="download" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="site" className="feature-section ptb-100 wow fadeIn" data-wow-delay="0.2s">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="section-heading text-center mb-5">
                            <span className="text-uppercase color-secondary sub-title"></span>
                            <h2>Объявления на сайте компании</h2>
                            <p></p>
                        </div>
                    </div>
                </div>

                <div className="row row-grid">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className="d-flex align-items-start mb-5">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-ad"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность публиковать объявления из Findy или Experium на карьерных страницах своей компании</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-5">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-widget"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность создавать любое количество виджетов объявлений и откликов и указывать, на каком домене (странице) они будут работать</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-5">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-widget-check"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность создавать виджеты для откликов по объявлениям и откликам без объявлений</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-domain"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность указывать при публикации – на каком домене (странице) будет опубликовано объявление</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="d-flex align-items-start mb-5">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-anketa"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность создать и настроить для каждого домена(страницы) отдельную анкету</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-5">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-check"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность создавать анкеты для откликов по объявлениям и без объявлений, а также задать предустановленные анкеты</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-5">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-add"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность самостоятельно создавать и редактировать вопросы в анкетах: 40 предустановленных вопросов, 20 резервных вопросов, неограниченное количество кастомных вопросов</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-gradient rounded-circle">
                                    <i className="icon icon-response"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0">Возможность получать отклики в Findy и автоматически передавать их в Experium</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="landing" className="download-section ptb-100 background-img wow fadeIn" data-wow-delay="0.2s" style={{background: `url(${BgLanding})no-repeat center center / cover`}}>
            <div className="container">
                <div className="row row-grid align-items-start">
                    <div className="col-xl-8 col-lg-7 col-md-6">
                        <div className="section-heading mb-5">
                            <h2 className="text-white">Объявления на лендингах</h2>
                        </div>

                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-constr"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Конструктор лендинговых страниц с библиотекой из 42-х предустановленных шаблонов</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-landing"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Возможность размещать на лендинговой странице любое количество блоков для разных вакансий</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-attach"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Возможность прикреплять к вакансии любое количество лендинговых страниц</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-draw"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Редактирование в лендингах любых элементов дизайна (цвета, шрифты, блоки, фон, заливка, вставляемые элементы, картинки, подложки, границы, кнопки, булеты и т.д.)</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-opd"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Возможность настройки текста ОПД (о персональных данных)</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-count"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Возможность добавления счетчиков Яндекс.Метрика и Google Analytics</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-jobot"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Настройка перехода соискателей в анкеты и/ или подключенных Чат-ботов</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-banner"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Настройка и публикация банеров для в соц.сетей, ведущих на нужную лендинговую страницу</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="pr-4">
                                <div className="ico ico-shape ico-bg-white rounded-circle">
                                    <i className="icon icon-experium"></i>
                                </div>
                            </div>
                            <div className="ico-text">
                                <p className="mb-0 text-white">Интеграция с Experium – возможность публиковать объявления из рекрутинговых проектов Experium</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 offset-xl-1 col-lg-4 offset-lg-1 col-md-5 offset-md-1">
                        <img width="auto" height="auto" src={Image2} alt="2" className="img-center img-fluid" />
                    </div>
                </div>
            </div>
        </section>

        <section className="screenshots-section ptb-100 wow fadeIn" data-wow-delay="0.2s">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="section-heading text-center">
                            <h2>Шаблоны лендингов</h2>
                            <p className="lead"></p>
                        </div>
                    </div>
                </div>
                <div className="screen-slider-content mt-5">
                    <div className="screenshot-frame"></div>
                    <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                        <img width="auto" height="auto" src={LandingScreen1} className="img-fluid" alt="screenshots" />
                        <img width="auto" height="auto" src={LandingScreen2} className="img-fluid" alt="screenshots" />
                        <img width="auto" height="auto" src={LandingScreen3} className="img-fluid" alt="screenshots" />
                        <img width="auto" height="auto" src={LandingScreen4} className="img-fluid" alt="screenshots" />
                        <img width="auto" height="auto" src={LandingScreen5} className="img-fluid" alt="screenshots" />
                        <img width="auto" height="auto" src={LandingScreen6} className="img-fluid" alt="screenshots" />
                    </div>
                </div>
            </div>
        </section>

        <section className="download-section ptb-100 gray-light-bg wow fadeIn" data-wow-delay="0.2s">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-5">
                        <div className="download-img d-flex align-items-end">
                            <img width="auto" height="auto" src={Image3} alt="download" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-7 pl-5">
                        <div className="section-heading mb-5">
                            <h2>Отклики с сайта компании и&nbsp;лендингов</h2>
                        </div>
                        <div className="download-content">
                            <ul className="bullet">
                                <li>Создание любого количества анкет для отклика</li>
                                <li>Возможность настроить маршрутизацию анкет по разным доменам, а также прикреплять к разным лендинговым страницам и доменам разные анкеты для отклика</li>
                                <li>Возможность выбора для каждой лендинговой страницы новой анкеты / предустановленной анкеты или анкеты по умолчанию</li>
                                <li>Настройка и редактирование анкет соискателя с возможностью установки правил заполнения этих анкет</li>
                                <li>Настройка библиотечных вопросов, дополнительных вопросов, отсекающих вопросов, блочных вопросов, ожидаемых вопросов</li>
                                <li>Настройка выпадающих справочников, классификаторов и словарей</li>
                                <li>Встроенный генератор UTM- меток (сбор всей статистики по шести каналам)</li>
                                <li>Автоматический сбор всех откликов в личном кабинете Findy</li>
                                <li>Интеграция с Experium – передача всех откликов в сводку проекта Experium, к которому относится лендинговая страница.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="chatbot" className="download-section pt-100 background-img wow fadeIn" data-wow-delay="0.2s" style={{background: `url(${BgChatbot})no-repeat center center / cover`}}>
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-7">
                        <div className="download-content pb-100">
                            <h2 className="text-white mb-5">Чат-боты</h2>
                            <ul className="bullet-white">
                                <li>Настройка и подключение Чат-ботов в личном кабинете Findy</li>
                                <li>Поддержка VIBER, Facebook Messenger, Telegram, Web-bot</li>
                                <li>Возможность гибкой привязки ботов на разных платформах к разным вакансиям</li>
                                <li>Возможность автоматического перехода соискателя в бота с вакансий на лендинговых страницах или на сайте компании</li>
                                <li>Автоматический вывод в бота вопросов из анкеты, которую должен заполнить соискатель</li>
                                <li>Автоматический сбор всех откликов в личном кабинете Findy</li>
                                <li>Возможность видео и аудио ответов</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="download-img d-flex align-items-end">
                            <img width="auto" height="auto" src={Image4} alt="download" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="social" className="contact-us ptb-100 wow fadeIn" data-wow-delay="0.2s">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <img width="auto" height="auto" src={Image5} alt="download" className="img-fluid" />
                    </div>
                    <div className="col-md-6 offset-md-1">
                        <div className="section-heading">
                            <h2>Социальные сети</h2>
                            <p>Ссылки на лендинговые страницы с Вашими объявлениями можно в один клик опубликовать в социальных сетях.</p>
                            <p>Для оформления постов в социальных сетях Findy позволяет создавать и настраивать баннеры.</p>
                            <p>Список доступных соцсетей в Findy:</p>
                            <ul className="bullet">
                                <li>Linkedin</li>
                                <li>Facebook</li>
                                <li>Twitter</li>
                                <li>ВКонтакте</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>;

export default Main;
